/* Colors */
/*5% darker than orange*/
/* Mixins */
.clickable:hover {
  cursor: pointer;
}
* {
  box-sizing: border-box;
  /* Unless marked with the class can-highlight, do not allow the user to highlight things on accident */
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Chrome and Opera */
}
*:focus {
  outline: none;
}
.can-highlight,
.can-highlight * {
  -webkit-touch-callout: text;
  /* iOS Safari */
  -webkit-user-select: text;
  /* Safari */
  -moz-user-select: text;
  /* Firefox */
  -ms-user-select: text;
  /* Internet Explorer/Edge */
  user-select: text;
  /* Chrome and Opera */
}
.App {
  height: 100vh;
  /* IPad "Fix"/hack - https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
  max-height: -webkit-fill-available;
}
.App input {
  user-select: all;
}
body {
  margin: 0;
  padding: 0;
}
body:not(.hacker-mode) {
  height: 100vh;
  /* IPad "Fix"/hack - https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/ */
  max-height: -webkit-fill-available;
  width: 100vw;
  overflow: hidden;
}
body h1,
body h2,
body h3,
body h4,
body h5,
body h6 {
  letter-spacing: 2px;
  color: white;
}
.station {
  height: 100%;
  width: 100%;
}
/* FLEX ATTRIBUTES */
.row,
.column {
  display: flex;
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}
.space-between {
  justify-content: space-between;
}
.center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-table {
  display: flex;
  flex-wrap: wrap;
}
.flex-table * {
  flex-grow: 1;
}
/* GOOGLE TRANSLATE STYLES */
body > .skiptranslate {
  display: none;
}
.goog-te-banner-frame.skiptranslate {
  display: none !important;
}
.goog-text-highlight {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
#goog-gt-tt,
#goog-gt-tt:hover {
  display: none !important;
}
body {
  top: 0px !important;
}
.goog-te-gadget {
  text-align: left;
  margin-left: 16px;
}
.goog-te-gadget img {
  background-color: white;
  border-radius: 4px;
  margin-right: 3px;
  padding: 1px;
}
.goog-te-combo {
  padding: 7px;
  border-radius: 6px;
  width: 150px;
}
.goog-te-spinner-pos {
  display: none;
}
.skiptranslate {
  color: #fff !important;
}
.goog-logo-link {
  color: #fff !important;
}
