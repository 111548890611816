@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	body {
		@apply font-game-body;
	}
	button,
	[role='button'] {
		cursor: default;
	}
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		@apply font-bold;
		@apply font-game-headline;
	}
	h1 {
		@apply text-4xl;
	}
	h2 {
		@apply text-3xl;
	}
	h3 {
		@apply text-2xl;
	}
	h4 {
		@apply text-xl;
	}
	h5 {
		@apply text-lg;
	}
	h6 {
		@apply text-base;
	}

	.outset-border {
		border-style: outset;
	}

	.inset-center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}

	:root {
		--canvas-border-width: theme(spacing.3);
		--canvas-border-radius: theme(spacing.6);
	}
}
