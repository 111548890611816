/* Colors */
/*5% darker than orange*/
/* Mixins */
.clickable:hover {
  cursor: pointer;
}
.CommunicationPlus .wifi.complete .bar.complete .Progress {
  background-color: var(--color-faded-on-base);
}
.CommunicationPlus .wifi .Progress {
  background-color: var(--color-faded-on-base);
}
.CommunicationPlus .communication-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 97%;
  height: 100%;
  justify-content: flex-end;
}
.CommunicationPlus .communication-board .coordinate-plane {
  pointer-events: none;
  height: 0px;
  color: white;
  display: flex;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  margin-bottom: -6px;
}
.CommunicationPlus .communication-board .coordinate-plane > * {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0;
}
.CommunicationPlus .communication-board .coordinate-plane .zero-180 {
  display: flex;
  width: 100%;
  align-items: flex-end;
  z-index: 1;
}
.CommunicationPlus .communication-board .coordinate-plane .zero-180 .line {
  flex: 1 1;
  height: 3px;
  background-color: #00beb4;
}
.CommunicationPlus .communication-board .coordinate-plane .zero-180 > * {
  margin: 0 5px;
  padding: 0;
}
.CommunicationPlus .communication-board .coordinate-plane .fortyFive-90 {
  transform: rotate(-45deg);
  z-index: 2;
}
.CommunicationPlus .communication-board .coordinate-plane .fortyFive-90 .number {
  transform: rotate(45deg);
}
.CommunicationPlus .communication-board .coordinate-plane .fortyFive-90 .box {
  border-bottom: 3px solid #00beb4;
  border-left: 3px solid #00beb4;
  width: 100%;
  height: 100%;
}
.CommunicationPlus .communication-board .coordinate-plane .fortyFive-90 .fortyFive {
  position: absolute;
  right: 0;
  bottom: 0;
}
.CommunicationPlus .communication-board .coordinate-plane .fortyFive-90 .oneThirtyFive {
  position: absolute;
  top: 0;
  left: 0;
}
.CommunicationPlus .communication-board .coordinate-plane .ninety {
  display: flex;
  flex-direction: column;
  width: 5px;
  text-align: center;
  z-index: 3;
}
.CommunicationPlus .communication-board .coordinate-plane .ninety .line {
  width: 3px;
  background-color: #00beb4;
  flex: 1 1;
}
.CommunicationPlus .communication-board .base-station-wrapper {
  width: 80px;
  position: absolute;
  margin-bottom: -4px;
  z-index: 2;
  display: flex;
  justify-content: center;
}
.CommunicationPlus .communication-board .base {
  width: 40%;
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}
.CommunicationPlus .communication-board .base .base-upper {
  background-color: #14143d;
  height: 25%;
  width: 80%;
  border-top-left-radius: 5% 100%;
  border-top-right-radius: 5% 100%;
}
.CommunicationPlus .communication-board .base .base-lower {
  height: 75%;
  width: 100%;
  border-top-left-radius: 10% 100%;
  border-top-right-radius: 10% 100%;
  background-color: var(--color-faded-on-base);
  display: flex;
  justify-content: center;
  align-items: center;
}
.CommunicationPlus .communication-board .base .base-lower > * {
  margin: 0 50px;
}
.CommunicationPlus .communication-board .base .base-lower .angle-input {
  height: 60%;
  width: 30%;
  border-radius: 8px;
  text-align: center;
  font-size: 2em;
}
.CommunicationPlus .communication-board .base .base-lower .button {
  color: white;
  background: linear-gradient(#003939, #006464);
  height: 60%;
  width: 30%;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 8px;
}
.CommunicationPlus .communication-board .base .base-lower .button.can-shoot {
  background: linear-gradient(#85bebe, #16dcd8);
}
.CommunicationPlus .communication-board .base .base-lower .button.can-shoot:hover {
  background: linear-gradient(#85bebe, #00beb4);
  cursor: pointer;
}
.CommunicationPlus .communication-board .wifi {
  position: absolute;
  right: 8px;
}
.CommunicationPlus .base-station {
  width: 100%;
}
.CommunicationPlus .base-station-base {
  z-index: 2;
}
.CommunicationPlus .base-station-dish {
  overflow: visible;
  position: absolute;
}
.CommunicationPlus .base-station-dish .communication-cls-1 {
  fill: url(#linear-gradient);
}
.CommunicationPlus .base-station-dish .communication-cls-2 {
  fill: url(#linear-gradient-2);
}
.CommunicationPlus .base-station-dish .communication-cls-3 {
  fill: url(#linear-gradient-3);
}
.CommunicationPlus .base-station-dish .communication-cls-4 {
  fill: url(#linear-gradient-4);
}
.CommunicationPlus .base-station-dish .communication-cls-5 {
  fill: url(#linear-gradient-5);
}
.CommunicationPlus .base-station-dish .communication-cls-6 {
  fill: url(#linear-gradient-6);
}
.CommunicationPlus .bullet {
  display: inline-block;
  border: 0px double transparent;
  border-right-color: currentColor;
  border-top-right-radius: 100% 100%;
  border-bottom-right-radius: 100% 100%;
  animation: signal 0.25s linear 0s infinite alternate;
}
.CommunicationPlus .target {
  animation: fadeIn 2s linear 0s 1 forwards;
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes signalReceived {
  10% {
    filter: drop-shadow(0 0 50px skyblue);
  }
  20% {
    filter: drop-shadow(0 0 50px transparent);
  }
  30% {
    filter: drop-shadow(0 0 50px skyblue);
  }
  40% {
    filter: drop-shadow(0 0 50px transparent);
  }
  50% {
    filter: drop-shadow(0 0 50px skyblue);
  }
  60% {
    filter: drop-shadow(0 0 50px transparent);
  }
  70% {
    filter: drop-shadow(0 0 50px skyblue);
    opacity: 1;
  }
  80% {
    filter: drop-shadow(0 0 50px transparent);
  }
  90% {
    filter: drop-shadow(0 0 50px skyblue);
  }
  100% {
    filter: drop-shadow(0 0 50px transparent);
    opacity: 0;
  }
}
@keyframes signal {
  from {
    color: #00534f;
    border-radius: 100%;
  }
  to {
    color: #00ecdf;
    border-radius: 50%;
  }
}
