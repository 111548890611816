/* Colors */
/*5% darker than orange*/
/* Mixins */
.clickable:hover {
  cursor: pointer;
}
.Progress {
  background-color: #151540;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 0 1px 5px #000 inset, 0 1px 0 #444;
}
.Progress span {
  position: relative;
  display: inline-block;
  background-color: #95effd;
  border-radius: 3px;
  box-shadow: 0 1px 0 rgba(149, 239, 253, 0.5) inset;
  transition: width 100ms;
}
.Progress .after-element {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fff;
  border-radius: 3px;
}
@keyframes animate-shine-horizontal {
  0% {
    opacity: 0;
    width: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    width: 95%;
  }
}
@keyframes animate-shine-vertical {
  0% {
    opacity: 0;
    height: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    height: 95%;
  }
}
