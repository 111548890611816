.ConnectionArea {
  width: 40%;
}
.ConnectionArea .grid {
  background-color: var(--color-faded-on-base);
  border: 4px solid var(--color-on-base);
  border-radius: 100px;
  margin-top: 15%;
  align-items: center;
  justify-items: center;
  display: grid;
  padding: 5%;
  height: 75%;
  grid-template-columns: repeat(6, 16.666%);
  width: 100%;
  grid-template-rows: repeat(6, 16.666%);
}
.ConnectionArea .warning {
  animation: blink;
  animation-duration: 0.6s;
  animation-iteration-count: 2;
  background-color: rgba(255, 0, 0, 0.7);
}
@keyframes blink {
  0%,
  100% {
    background-color: #173047;
  }
  50% {
    background-color: red;
  }
}
.ConnectionArea svg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.ConnectionArea svg.myLines line {
  pointer-events: visibleStroke;
  cursor: pointer;
}
.ConnectionArea line {
  stroke: white;
  stroke-width: 8px;
  stroke-linecap: round;
}
