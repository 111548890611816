/* Colors */
/*5% darker than orange*/
/* Mixins */
.clickable:hover {
  cursor: pointer;
}
.ThrottleSlider {
  height: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  /**
* Rangeslider - Vertical slider
*/
  /**
* Rangeslider - Labels
*/
}
.ThrottleSlider .rangeslider {
  display: block;
  position: relative;
  left: 8vw;
}
.ThrottleSlider .rangeslider,
.ThrottleSlider .rangeslider .rangeslider__fill {
  box-shadow: 0 0 1vh #151540;
  display: block;
}
.ThrottleSlider .rangeslider-vertical {
  height: 100%;
  max-width: 8vw;
  background-color: #151540;
  border-radius: 1vh;
}
.ThrottleSlider .rangeslider-vertical .rangeslider__fill,
.ThrottleSlider .rangeslider-vertical .rangeslider__handle {
  position: absolute;
}
.ThrottleSlider .rangeslider-vertical .rangeslider__fill {
  width: 100%;
  bottom: 0;
  background-image: linear-gradient(to top, #95effd, rgba(149, 239, 253, 0.5));
  border-bottom-left-radius: 1vh;
  border-bottom-right-radius: 1vh;
  box-shadow: 0 0 1vh #151540;
}
.ThrottleSlider .rangeslider-vertical .rangeslider__fill::after {
  content: '';
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #5c6992;
  border-radius: 5px;
  animation: animate-shine 3s ease-out infinite;
}
.ThrottleSlider .rangeslider-vertical .rangeslider__handle {
  width: 14vw;
  height: 8vh;
  left: -3vw;
  z-index: 1;
  border-radius: 2vh;
  border: 1vh outset #c0c0c0;
  background-color: #c0c0c0;
  cursor: pointer;
  box-shadow: 0 0 0.25vh #00000D;
}
.ThrottleSlider .rangeslider__labels {
  position: relative;
  width: 72px;
  transform: translate(-14vw, 20px);
}
.ThrottleSlider .rangeslider__labels .rangeslider__label-item {
  font-size: 3em;
  text-align: center;
  font-family: 'Orbitron', sans-serif;
  color: #A2E8FF;
  cursor: pointer;
}
.ThrottleSlider .rangeslider__labels .rangeslider__label-item:last-child {
  color: #cc324c;
}
.ThrottleSlider .rangeslider__labels .rangeslider__label-item:first-child {
  color: #39fdc1;
}
.ThrottleSlider .rangeslider__labels .rangeslider__label-item:nth-child(2) {
  color: #9acd32;
}
.ThrottleSlider .rangeslider__labels .rangeslider__label-item:nth-child(3) {
  color: yellow;
}
.ThrottleSlider .rangeslider__labels .rangeslider__label-item:nth-child(4) {
  color: #ffae42;
}
.ThrottleSlider .rangeslider__labels .rangeslider__label-item:nth-child(5) {
  color: #ffae42;
}
.ThrottleSlider .rangeslider__labels .rangeslider__label-item::before {
  display: none;
}
@keyframes animate-shine {
  0% {
    opacity: 0;
    height: 95%;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    height: 0;
  }
}
