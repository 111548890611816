/* Colors */
/*5% darker than orange*/
/* Mixins */
.clickable:hover {
  cursor: pointer;
}
.wifi {
  position: absolute;
  right: 30px;
  color: white;
  bottom: 2vh;
  height: 20vh;
  width: 20vw;
  display: flex;
  z-index: 2;
  justify-content: flex-end;
  pointer-events: none;
}
.wifi * {
  pointer-events: none;
}
.wifi.complete .bar.complete div.Progress {
  background-color: #39fdc1;
}
.wifi.complete .bar.complete .after-element {
  background-color: #05b2ae;
}
.wifi.complete .bar.complete span {
  background-color: #025857;
}
.wifi .bar {
  margin-top: auto;
  margin-left: 2px;
  margin-right: 2px;
  position: relative;
}
.wifi .bar .after-element {
  background-color: #5c6992;
}
.wifi .bar span {
  background-color: #5d578d;
}
.wifi .bar .Progress {
  background-color: #151540;
}
.wifi .bar.complete .Progress {
  background-color: #151540;
}
.wifi .bar.complete .after-element {
  background-color: rgba(149, 239, 253, 0.5);
}
.wifi .bar.complete span {
  background-color: #95effd;
}
.wifi .progress-1 {
  height: 25%;
}
.wifi .progress-2 {
  height: 50%;
}
.wifi .progress-3 {
  height: 75%;
}
.wifi .progress-4 {
  height: 100%;
}
